<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="3">
                  <v-select placeholder="Pilih Bulan" @input="doFill" v-model="filter.month" :options="Config.mr.Month" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </b-col>
                <b-col lg="3">
                  <div class="d-flex">
                      
                    <validation-observer
                      v-slot="{ handleSubmit }" ref="VForm"
                    >
                      <b-form @submit.prevent="handleSubmit(doFill())">
                        <b-input-group>
                        <input type="text" @keyup="onlyNumDeb" @keydown="onlyNumDeb" v-model="filter.year" class="form-control" placeholder="Masukan Tahun ...">
                        </b-input-group>
                        <VValidate 
                            name="Tahun" 
                            v-model="filter.year" 
                            :rules="{min:4,max:4}"
                        />
                      </b-form>
                    </validation-observer>
                      <!--
                      <b-button
                          class="ml-1 d-inline-flex align-items-center"
                          variant="outline-success"
                          id="resetBtn"
                          @click="doFill"
                      >Cari</b-button>
                      -->
                  </div>
                </b-col>
                <b-col lg="3">
                  <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status" :options="Config.mr.StatusOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <div class="text-right">
                <b-button
                  class="ml-1 d-inline-flex align-items-center"
                  variant="outline-success"
                  id="resetBtn"
                  @click="doReset()"
                >Reset</b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            <template
              #cell(mtp_amount) = "data"
            >
              Rp. {{data.value}}
            </template>
            <template
              #cell(mtp_date) = "data"
            > 
              {{data.value | moment("MMMM YYYY")}}
            </template>
            <template
              #cell(mtp_is_active)="data"
            >
              <b-badge
                v-if="data.value=='Y'"
                variant="success"
              >Aktif</b-badge>
              <b-badge
                v-else
                variant="danger"
              >Nonaktif</b-badge>
            </template>
            <template
              #cell(action)="data"
            >
              <div class="list-icons">
                <router-link class="list-icons-item"
                  v-if="moduleRole('edit')"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                  data-toggle="tooltip" data-placement="top" title="View"><i
                    class="icon-pencil5"></i></router-link>
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('delete')"
                  @click="doDelete(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Delete"><i
                    class="icon-bin"></i></a>
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('changeStatus')"
                  @click="doChangeStatus(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Change Status"><i
                    class="icon-cog6"></i></a>
              </div>
              <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'mtp_id',
      statusKey:'mtp_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'mtp_amount',
          label: 'Jumlah',
        },
        {
          key: 'mtp_date',
          label: 'Periode',
        },
        {
          key: 'mtp_is_active',
          label: 'Status',
        },
        {
          key: 'action',
        },
      ],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>