<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit(doSubmit())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>     
        <b-row>
            <b-col lg="6">
              <b-form-group>
              <label>Periode<span class="text-danger mr5">*</span></label>
              <datepicker minimum-view="month" maximum-view="year" placeholder="masukkan periode" :format="DatePickerFormat" :language="id" v-model="row.mtp_date" input-class="form-control transparent"></datepicker>
              </b-form-group>
              <VValidate 
                  name="Periode" 
                  v-model="row.mtp_date" 
                  :rules="{required:1}" 
              />
            </b-col>

            <b-col lg="6">
                <b-form-group>
                <label>Jumlah <span class="text-danger mr5">*</span></label>
                <div class="input-group">
                    <span class="input-group-prepend">
                        <span class="input-group-text">Rp</span>
                    </span>
                    <b-form-input type="text" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')" v-model="row.mtp_amount" class="form-control" placeholder="500.000.000">
                    </b-form-input>
                </div>
                <VValidate 
                    name="Jumlah" 
                    v-model="row.mtp_amount" 
                    :rules="{regex:/^[0-9\.]+$/, required:1, min:1}" 
                />
                </b-form-group>
            </b-col>
            <b-col lg="6">
                <b-form-group class="mt-3">
                    <label>Status <span class="text-danger mr5">*</span></label>
                    <b-form-radio-group
                        :options="Config.mr.StatusOptions"
                        v-model="row[$parent.statusKey]"
                    />
                    <VValidate 
                        name="Status" 
                        v-model="row[$parent.statusKey]" 
                        :rules="{required:1}" 
                    />
                </b-form-group>
            </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Datepicker from 'vuejs-datepicker'
import {id} from 'vuejs-datepicker/dist/locale'
export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  components: {
    Datepicker
  },
  data(){
    return {
      DatePickerFormat: 'MMMM yyyy',
      id: id      
    }
  },
  methods:{
    doSubmit(){
      return this.$parent.doSubmit(
          "/do/BoMTargetPencairan",
          _.assign({type:this.$parent.isAdd?'add':'update'}, {id: (this.$parent.pageId||this.$parent.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
                this.$router.push({name:this.$route.name})
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                    console.log(i)
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return this.$swal({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VForm', this.$refs
      )
    }
  }
}
</script>